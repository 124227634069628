<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <zw-select-group v-model="inputVal.account"
                         v-if="!loading"
                         :options="getBankAccounts()"
                         name="account"
                         :label-prefix="labelPrefix"
                         validate="required"
        ></zw-select-group>
    </div>

</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'SyncBankTransactions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    mounted() {
        const bankAccountsFetch = this.$store.dispatch('BankTransfers/fetchBankAccounts')

        Promise.all([bankAccountsFetch]).finally(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapGetters('BankTransfers', ['getBankAccounts']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>